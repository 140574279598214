import { Box, Typography } from '@mui/material';
import React from 'react';
import { sectionFeaturedColumnistInfo } from './featureInfo';
import { SliderFeaturedColumninst } from '../../../shared/components/slider/SliderFeaturedColumninst';
import { PlanSectionTitle } from '../../../shared/components/Text';

export const FeaturedColumnists: React.FC = () => {
  const items = sectionFeaturedColumnistInfo.map((item, index) => (
    <Box
      key={index}
      sx={{
        px: 1,
        display: 'flex',
        justifyContent: 'center',
        cursor: 'pointer',
      }}>
      <Box>
        <img
          alt="Columnista destacado"
          src={item.imgUrl}
          width={'95px'}
          height={'100px'}
          style={{ userSelect: 'none', pointerEvents: 'none',
          }} 
          draggable={false}

        />
        <Typography fontSize={14}>{item.name}</Typography>
      </Box>
    </Box>
  ));

  return (
    <Box>
      <PlanSectionTitle marginBottom={2}>
        Columnistas destacados
      </PlanSectionTitle>
      <SliderFeaturedColumninst items={items} infinite={false} />
    </Box>
  );
};
