import { createAction } from '@cobuildlab/react-simple-state';
import {
  checkoutPlanSubscriptionEvent,
  checkoutPlanSubscriptionEventError,
} from './plan-events';
import { stripe } from '../../shared/stripe/config';
import { getRedirectUrl } from '../auth/auth-utils';
import { eventMetaStore } from '../easy-data/event-data-stores';
import { easyDataAuthenticate } from '../easy-data/easy-data-actions';
import { easyDataAuthenticateEvent } from '../easy-data/easy-data-events';

export const checkoutPlanSubscription = createAction(
  checkoutPlanSubscriptionEvent,
  checkoutPlanSubscriptionEventError,
  async (props: {
    email: string;
    priceId: string;
    stateUrl: string;
  }): Promise<void> => {
    const origin = window.location.origin;
    const redirect = getRedirectUrl();
    const url = window.location.href;

    const { eventMeta } = eventMetaStore.get();
    const uuid = easyDataAuthenticateEvent.get()?.uuid;
    let data = {};

    eventMeta.forEach((meta) => {
      data = {
        ...data,
        [meta.meta_key as string]: meta.meta_value,
      };
    });

    const session = await stripe.checkout.sessions.create({
      mode: 'subscription',
      locale: 'es',
      customer_email: props.email,
      allow_promotion_codes: false,
      line_items: [
        {
          price: props.priceId,
          quantity: 1,
        },
      ],
      metadata: { event_meta: JSON.stringify({ ...data, uuid }) },
      success_url: redirect !== '/' ? redirect : `${origin}?session_id={CHECKOUT_SESSION_ID}&state=${props.stateUrl}`,
      cancel_url: `${url}?returningStripe=true`,
    });

    if (!session.url) {
      throw new Error('Error al crear la sesion de pagos.');
    }


    window.location.href = session.url;

    return;
  },
);


