import { FC, PropsWithChildren } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_KEY } from '../../../shared/constans';
import { Loader } from '../../../shared/components/Loader';
import { useCreatePaymentSetup } from '../card-hooks';

const stripePromise = loadStripe(STRIPE_KEY);

export const StripeWrapperSetupPayment: FC<PropsWithChildren> = ({
  children,
}) => {
  const { data, loading } = useCreatePaymentSetup();

  return loading ? (
    <Loader />
  ) : (
    <Elements
      stripe={stripePromise}
      options={{
        loader: 'always',
        clientSecret: data?.client_secret ?? '',
        locale: 'es',
        appearance: {
          theme: 'stripe',
          variables: {
            borderRadius: '10px',
            fontSizeBase: '16px',
            spacingUnit: '5px',
            spacingGridRow: '20px',
            focusBoxShadow: 'none',
            focusOutline: '1px solid ',
            colorPrimary: 'black',
          },
        },
      }}>
      {children}
    </Elements>
  );
};
