import { Box, Link, Typography } from '@mui/material';
import React from 'react';
type Props = {
  cardInfo: {
    title: string;
    imgUrl: string;
    description: string;
    url: string;
  };
};

export const PlanAchivementCard: React.FC<Props> = ({ cardInfo }) => {
  return (
    <Box
      position={'relative'}
      padding={1}
      maxWidth={368}
      bgcolor={'#ffffff'}
      borderRadius={1}
      component={'div'}
      height={410}
      style={{ boxShadow: '0px 4px 12px 0px #00000029' }}
      mb={2}>
      <Box
        display={'flex'}
        justifyContent={'center'}
        padding={'2px 5px'}
        bgcolor={'#000000'}>
        <Typography
          textAlign={'center'}
          fontSize={14}
          color={'#ffffff'}
          style={{ textTransform: 'uppercase' }}
          fontWeight={700}
          letterSpacing={'0.7px'}>
          {cardInfo.title}
        </Typography>
      </Box>
      <Box marginTop={1} paddingBottom={1} borderBottom={'1px solid black'}>
        <img
          alt={cardInfo.title}
          src={cardInfo.imgUrl}
          width={'100%'}
          height={'195px'}
          style={{ objectFit: 'cover' }}
        />
      </Box>
      <Box marginTop={1}>
        <Typography fontWeight={400}>{cardInfo.description}</Typography>
      </Box>
      <Box
        position={'absolute'}
        bottom={5}
        left={9}
        component={'div'}
        display={'inline-flex'}>
        <Box
          display={'inline-flex'}
          flexWrap={'wrap'}
          alignItems={'center'}
          paddingY={'10px'}>
          <Typography fontSize={12} marginRight={0.5}>
            Más información:
          </Typography>
          <Link
            href={cardInfo.url}
            target={'_blank'}
            color={'#000000'}
            rel="noopener noreferrer"
            underline="always">
            <Typography fontSize={12}>{cardInfo.url}</Typography>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};
