import React from 'react';
import { Box, Stack } from '@mui/material';
import { FeatureWithImageCard } from './FeatureWithImageCard';
import { infoFeaturesPlans } from './featureInfo';
import { SliderFeaturesPlan } from '../../../shared/components/slider/SliderFeaturesPlan';
import { PlanSectionTitle } from '../../../shared/components/Text';

export const PlansSectionTwo : React.FC =() =>{
    const items = infoFeaturesPlans.map((item,index)=>
        <Box paddingX={1} key={index}>
            <FeatureWithImageCard cardInfo={item} />
        </Box>
    );

    return(
        <Box>
            <PlanSectionTitle pt={2}>
                ¿Qué obtienes al unirte?
            </PlanSectionTitle>

            <SliderFeaturesPlan items={items} infinite={false}/>
        </Box>
    );
};