import { IterPost } from '../../shared/types/generated';

export const generateUrlPost = (
  post:  IterPost,
): string => {
  console.log({ post });


  return post.url ??'';
};
