import React from 'react';
import {
  PaypalStatuses,
  Plan,
  usePlansQuery,
} from '../../shared/types/generated';
import { useNavigate } from 'react-router-dom';
import { Container, Grid } from '@mui/material';
import { PaymentMethodCard } from './components/PaymentMethodCard';
import { BackButton } from '../../shared/components/buttons/BackButton';
import { PaymentMethodCardLoading } from './components/PaymentMethodCardLoader';
import { getPostUrl } from '../auth/auth-utils';

export const PaymentMethod: React.FC = () => {
  const navigate = useNavigate();

  const { data, loading } = usePlansQuery({
    variables: {
      where: {
        status: PaypalStatuses.Active,
      },
    },
  });

  return (
    <Container>
      <Grid container pb={6} justifyContent="center" alignItems="center">
        <Grid item xs={10} mb={1}>
          <BackButton
            onClick={() => {
              navigate(`/planes?post_url=${getPostUrl()}`);
            }}
          />
        </Grid>
        <Grid item xs={10}>
          {loading ? (
            <PaymentMethodCardLoading />
          ) : (
            <PaymentMethodCard items={(data?.plans?.items || []) as Plan[]} />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
