import { styled, Typography } from '@mui/material';

export const PlanSectionTitle = styled(Typography)(({ theme }) => ({
  paddingInline: '5px',
  fontWeight: 700,
  fontSize: '32px !important',
  [theme.breakpoints.down('md')]: {
    fontSize: '24px !important',
  },
}));
