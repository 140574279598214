import { Box, Typography } from '@mui/material';
import React, { ReactNode, useState } from 'react';
type Props = {
    cardInfo:{
        title: string;
        imgUrl: string;
        description: string;
        author: string;
    }
}

export const PrizeCard: React.FC<Props> = ({cardInfo}) =>{
    return(
        <Box position={'relative'} maxWidth={368} bgcolor={'#ffffff'} borderRadius={'6px'} component={'div'} height={400} style={{boxShadow: '0px 4px 12px 0px #00000029'}} marginBottom={2}>
            <Box display={'flex'} padding={'5px 15px'} bgcolor={'#000000'} borderRadius="6px 6px 0 0">
                <Typography fontSize={14} color={'#ffffff'} style={{textTransform:'uppercase'}} fontWeight={700} letterSpacing={'0.7px'}>
                    {cardInfo.title}
                </Typography>
            </Box>
            <Box >
                <img alt={cardInfo.title} src={cardInfo.imgUrl} width={'100%'} height={'195px'} style={{objectFit:'cover'}}/>
            </Box>
            <Box padding={'10px'}>
                <Typography fontWeight={700}>
                    {cardInfo.description}
                </Typography>
            </Box>
            <Box position={'absolute'} bottom={5} left={9} component={'div'} marginTop={2} role='button' style={{cursor:'pointer'}} display={'inline-flex'}>
                <Box display={'inline-flex'} alignItems={'center'} paddingY={'10px'}>
                <img alt={'icono camara'} src={'./camera-icon.png'} width={38} height={38} style={{objectFit:'contain', marginRight:10}}/>
                <Typography>
                    Por: {cardInfo.author}
                </Typography>
                </Box>
            </Box>
        </Box>
    );

};