import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';
import { authStepAction } from '../auth-actions';
import { AuthStep } from '../auth-types';
import { PRIMARY_COLOR } from '../../../shared/css/theme';

const StyledTypography = styled(Typography)({
  fontSize: 18,
  fontWeight: 'bold!important',
});

const StyledButton = styled(ButtonDefault)({
  borderRadius: '19px!important',
  backgroundColor: `${PRIMARY_COLOR}!important`,
  border: 'none!important',
  color: 'white!important',
  '&:disabled': {
    backgroundColor: '#FFC7A4!important',
  },
  width: 200,
});

export const AuthForgotPasswordNotFound: React.FC = () => {
  return (
    <Box width="100%">
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={10} style={{ textAlign: 'center' }}>
          <Box mb={4}>
            <Box mb={4}>
              <img
                src="/vanguardianegro.png"
                alt="logo"
                width={225}
                height={25}
              />{' '}
            </Box>
            <StyledTypography variant="subtitle1">
              Este enlace expiro porfavor solucitar el cambio de contraseña
              nueva mente y volver al campo de correo
            </StyledTypography>
          </Box>
        </Grid>

        <Grid item xs={12} mb={3}>
          <Box textAlign="center">
            <StyledButton
              variant="contained"
              size="large"
              color="secondary"
              onClick={() => authStepAction(AuthStep.authEmail)}
            >
              VOLVER
            </StyledButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
