import { createStore } from '@cobuildlab/react-simple-state';
import { EventMeta } from './easy-data-types';


export const eventMetaStore = createStore<{
  eventMeta: EventMeta[]
}>({
  initialValue: {
    eventMeta: [],
  },
});