import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useBreakPoint } from '../../../shared/hooks/useBreakPoint';
type Props={
    title:string;
    description?:string;
}
export const DropdownDescription:React.FC<Props> = ({title,description}) =>{
    const { isBelow } = useBreakPoint('md');
    const [open, setOpen] = useState<boolean>(false);
    return(
        <Box padding={2} component={'div'} style={{background:'#ffffff',boxShadow:' 0px 4px 12px 0px #00000029'}}>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} paddingBottom={'2px'} borderBottom={'1px solid black'}>
                <Typography fontSize={isBelow ? 16 : 20} fontWeight={700}>{title}</Typography>
                <Box role='button' component={'div'} style={{cursor:'pointer'}} tabIndex={0} onClick={()=>setOpen(!open)}>
                    <img  src="./arrow-down.svg" alt="icono flecha abajo"  width={'15px'} height={'8px'}/>
                </Box>
            </Box>
            {
                open&&
                <Box marginTop={1}>
                    <Typography>
                        {description}
                    </Typography>
                </Box>
            }
        </Box>
    );
};