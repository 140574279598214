export const infoFeaturesPlans = [
    {
        title: 'Lectura Ilimitada',
        imgUrl:'./lectura-ilimitada.png',
        abstract:'Accede a nuestros artículos exclusivos sin anuncios ni interrupciones',
        description:[
            <><strong>Tu membresía, ¡se paga sola!</strong></>,
            <>Utiliza tu Membresía Vanguardia en los más de <strong>60 negocios</strong> locales para acceder a ofertas y promociones <strong>exclusivas para miembros de nuestra comunidad</strong> de lectores vanguardistas.</>,
            <>Todos los días hay <strong>premios especiales en la tienda</strong> que nuestros miembros activos están disfrutando.</>,
            <>¿Qué esperas para informarte todos los días y de paso, <strong>ahorrarte un dinerito?</strong></>
        ]
    },
    {
        title: 'Escuchar Artículos',
        imgUrl:'./escuchar-articulos.png',
        abstract:'Accede a nuestros artículos y autores exclusivos sin anuncios ni interrupciones',
        description:[
            <><strong>Tu membresía, ¡se paga sola!</strong></>,
            <>Utiliza tu Membresía Vanguardia en los más de <strong>60 negocios</strong> locales para acceder a ofertas y promociones <strong>exclusivas para miembros de nuestra comunidad</strong> de lectores vanguardistas.</>,
            <>Todos los días hay <strong>premios especiales en la tienda</strong> que nuestros miembros activos están disfrutando.</>,
            <>¿Qué esperas para informarte todos los días y de paso, <strong>ahorrarte un dinerito?</strong></>
        ]
    },
    {
        title: 'Ofertas unicas',
        imgUrl:'./ofertas-unicas.png',
        abstract:'Accede a nuestras promociones exclusivas',
        description:[
            <><strong>Tu membresía, ¡se paga sola!</strong></>,
            <>Utiliza tu Membresía Vanguardia en los más de <strong>60 negocios</strong> locales para acceder a ofertas y promociones <strong>exclusivas para miembros de nuestra comunidad</strong> de lectores vanguardistas.</>,
            <>Todos los días hay <strong>premios especiales en la tienda</strong> que nuestros miembros activos están disfrutando.</>,
            <>¿Qué esperas para informarte todos los días y de paso, <strong>ahorrarte un dinerito?</strong></>
        ]
    },
    {
        title: 'Lectura Ilimitada',
        imgUrl:'./lectura-ilimitada-two.png',
        abstract:'Accede a nuestros artículos exclusivos sin anuncios ni interrupciones',
        description:[
            <><strong>Tu membresía, ¡se paga sola!</strong></>,
            <>Utiliza tu Membresía Vanguardia en los más de <strong>60 negocios</strong> locales para acceder a ofertas y promociones <strong>exclusivas para miembros de nuestra comunidad</strong> de lectores vanguardistas.</>,
            <>Todos los días hay <strong>premios especiales en la tienda</strong> que nuestros miembros activos están disfrutando.</>,
            <>¿Qué esperas para informarte todos los días y de paso, <strong>ahorrarte un dinerito?</strong></>
        ]
    }
];

export const sectionFourInfo=[
    {
        title: 'PREMIO INTERNACIONAL DE FOTOGRAFÍA',
        imgUrl:'./setion-four-one.png',
        description:'La tribu de haitianos que recorrió más de 10 países en busca de un hogar.',
        author: 'OMAR SAUCEDO'
    },
    {
        title: 'PREMIO INTERNACIONAL DE FOTOGRAFÍA',
        imgUrl:'./setion-four-two.png',
        description:'La tribu de haitianos que recorrió más de 10 países en busca de un hogar.',
        author: 'OMAR SAUCEDO'
    },
    {
        title: 'PREMIO INTERNACIONAL DE FOTOGRAFÍA',
        imgUrl:'./setion-four-three.png',
        description:'La tribu de haitianos que recorrió más de 10 países en busca de un hogar.',
        author: 'OMAR SAUCEDO'
    }
];
export const sectionFiveInfo=[
    {
        title: 'REFORESTAR',
        imgUrl:'./section-five-one.png',
        description:'Con tu membresía, apoyas a Vanguardia a impulsar proyectos de reforestación y conservación que combaten el cambio climático, capturando CO2 y convirtiéndoloen vida para nuestro planeta.',
        url: 'https://printreleaf.com/MedioPliego'
    },
    {
        title: 'SOSTENER',
        imgUrl:'./section-five-two.png',
        description:'Con tu membresía contribuyes a promover la sostenibilidad, financiando proyectos verdes que reducen la huella de carbono y fomentan un futuro más limpio y verde para todos.',
        url: 'https://printreleaf.com/MedioPliego'
    },
    {
        title: 'SECUESTRO DE CARBONO',
        imgUrl:'./section-five-three.png',
        description:'Con tu membresía apoyas a Vanguardia en la inversión en proyectos de secuestro de carbono, esenciales para capturar emisiones y avanzar hacia un equilibrio ecológico vital.',
        url: 'https://printreleaf.com/MedioPliego'
    }
];

export const sectionSixInfo=[
    {
        title: 'MEDIMOS',
        imgUrl:'./section-six-one.png',
        description:'PrintReleaf recopila los datos de consumo de papel de Medio Pliego a través de integraciones automatizadas o mediante transferencias de datos mensuales simplificadas.',
        url: 'https://printreleaf.com/MedioPliego'
    },
    {
        title: 'CALCULAMOS',
        imgUrl:'./section-six-two.png',
        description:'Nuestro consumo de papel se analiza para calcular cuántos árboles fueron talados y cuánto gas de efecto invernadero se emitió por el papel consumido.',
        url: 'https://printreleaf.com/MedioPliego'
    },
    {
        title: 'COMPENSAMOS',
        imgUrl:'./section-six-three.png',
        description:'PrintReleaf planta árboles en proyectos de reforestación global certificados y/o compensa el carbono asociado con la producción de los materiales.',
        url: 'https://printreleaf.com/MedioPliego'
    }
];

export const sectionFeaturedColumnistInfo=[
    {
        name: 'Gerson Gómez',
        imgUrl:'./columnistas/Gerson-Gomez.png',
    },
    {
        name: 'Plácido Garza',
        imgUrl:'./columnistas/Placido-Garza.png',
    },
    {
        name: 'Ángel García',
        imgUrl:'./columnistas/Angel-Garcia.png',
    },
    {
        name: 'Gerson Gómez',
        imgUrl:'./columnistas/Gerson-Gomez.png',
    },
    {
        name: 'Plácido Garza',
        imgUrl:'./columnistas/Placido-Garza.png',
    },
    {
        name: 'Ángel García',
        imgUrl:'./columnistas/Angel-Garcia.png',
    },
    {
        name: 'Gerson Gómez',
        imgUrl:'./columnistas/Gerson-Gomez.png',
    },
    {
        name: 'Plácido Garza',
        imgUrl:'./columnistas/Placido-Garza.png',
    },
    {
        name: 'Ángel García',
        imgUrl:'./columnistas/Angel-Garcia.png',
    },
    {
        name: 'Gerson Gómez',
        imgUrl:'./columnistas/Gerson-Gomez.png',
    },
    {
        name: 'Plácido Garza',
        imgUrl:'./columnistas/Placido-Garza.png',
    },
    {
        name: 'Ángel García',
        imgUrl:'./columnistas/Angel-Garcia.png',
    },
];