import { Box, Typography } from '@mui/material';
import React, { ReactNode, useState } from 'react';
type Props = {
  cardInfo: {
    title: string;
    imgUrl: string;
    abstract: string;
    description: ReactNode[];
  };
};

export const FeatureWithImageCard: React.FC<Props> = ({ cardInfo }) => {
  const [openMoreInfo, setOpenMoreInfo] = useState(false);
  return (
    <Box
      sx={{
        p: 1,
        position: 'relative',
        bgcolor: '#ffffff',
        borderRadius: '4px',
        height: '360px',
        boxShadow: '0px 4px 12px 0px rgba(0,0,0,0.16)',
        my: 2,
      }}>
      <Box
        display={'inline-flex'}
        padding={'5px 15px'}
        bgcolor={'#000000'}
        borderRadius={1}>
        <Typography
          color={'#ffffff'}
          sx={{ textTransform: 'uppercase' }}
          fontWeight={700}
          fontSize={'12px'}
          lineHeight={'12px'}
          letterSpacing={'0.19'}>
          {cardInfo.title}
        </Typography>
      </Box>
      {openMoreInfo ? (
        <Box height={290}>
          {cardInfo.description.map((item, index) => (
            <Box marginTop={1} key={index}>
              <Typography fontSize={'14px'} lineHeight={'22px'}>
                {item}
              </Typography>
            </Box>
          ))}
        </Box>
      ) : (
        <>
          <Box marginTop={1} paddingBottom={1} borderBottom={'1px solid black'}>
            <img
              alt={cardInfo.title}
              src={cardInfo.imgUrl}
              width={'100%'}
              height={'195px'}
              style={{ objectFit: 'cover' }}
            />
          </Box>
          <Box marginTop={1}>
            <Typography fontWeight={700}>{cardInfo.abstract}</Typography>
          </Box>
        </>
      )}
      <Box
        position={'absolute'}
        bottom={10}
        left={9}
        component={'div'}
        marginTop={2}
        role="button"
        style={{ cursor: 'pointer' }}
        onClick={() => {
          setOpenMoreInfo(!openMoreInfo);
        }}
        display={'inline-flex'}>
        <Typography style={{ textDecoration: 'underline' }} fontSize={14}>
          {`${openMoreInfo ? 'Menos' : 'Mas'}`} información
        </Typography>
      </Box>
    </Box>
  );
};
