import { Breakpoint, useMediaQuery, useTheme } from '@mui/material';

interface UseBreakPointReturn {
  isBelow: boolean;
  isAbove: boolean;
}

export const useBreakPoint = (breakpoint: Breakpoint): UseBreakPointReturn => {
  const theme = useTheme();
  const isBelow = useMediaQuery(theme.breakpoints.down(breakpoint));
  const isAbove = useMediaQuery(theme.breakpoints.up(breakpoint));

  return { isBelow, isAbove };
};
