import React, { ChangeEvent, useState } from 'react';
import { Box, Grid, TextField, Theme, Typography } from '@mui/material';
import { useAuth } from '../../auth/auth-hook';
import { GoogleConnectButton } from './GoogleConnectButton';
import { FacebookConnectButton } from './FacebookConnectButton';
import { AppleConnectButton } from './AppleConnectButton';
import { DialogDefault } from '../../../shared/components/dialog/DialogDefault';
import { useStore } from '@cobuildlab/react-simple-state';
import {
  changeEmailDialogStoreAction,
  providerConnectStoreAction,
} from '../setting-actions';
import { providerConnectStore } from '../setting-events';
import {
  useDisconnectUserProviderMutation,
  useUpdateUserEmailMutation,
} from '../../../shared/types/generated';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';
import { createStyles, makeStyles } from '@mui/styles';
import { PRIMARY_COLOR } from '../../../shared/css/theme';
import { changeEmailDialogStore } from '../setting-stores';
import { openDialogAlert } from '../../alert/alert-actions';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    changeEmailButton: {
      background: `${PRIMARY_COLOR}!important`,
      color: 'white!important',
      width: '130px',
      height: '38px !important',
      fontSize: 12,
    },
    input: {
      background: 'white',
      fontSize: '18px',
      height: '38px',
      marginBottom: 5,
      '& + .MuiFormHelperText-root': {
        margin: 0,
      },
    },
  }),
);

type ChangeEmailData = {
  email: string;
  password: string;
};

export const SettingAccount: React.FC = () => {
  const { provider: socialProvider, refetch, user } = useAuth();
  const { isOpen, provider } = useStore(providerConnectStore);
  const { open } = useStore(changeEmailDialogStore);
  const [changeEmailData, setChangeEmailData] = useState<ChangeEmailData>({
    email: '',
    password: '',
  });
  const classes = useStyles();

  const [callDeleteAction, { loading }] = useDisconnectUserProviderMutation({
    onCompleted: () => {
      refetch();
      providerConnectStoreAction(false, undefined);
    },
  });
  const [callUpdateEmailAction, { loading: loadingUpdateEmail }] =
    useUpdateUserEmailMutation({
      onCompleted: () => {
        changeEmailDialogStoreAction(false);
        openDialogAlert(
          'Revisa tu correo electrónico para confirmar el cambio',
        );
      },
      onError: (error) => {
        openDialogAlert(error.message);
      },
    });

  const onChangeHandler = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void => {
    setChangeEmailData({
      ...changeEmailData,
      [event.target.name as keyof ChangeEmailData]: event.target
        .value as string,
    });
  };

  return (
    <>
      <Box>
        <Grid container mb={2}>
          <Grid item xs={12} md={3}>
            <Typography variant="subtitle1" fontWeight="bold">
              Nombre de usuario
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Typography variant="subtitle1">{user?.name}</Typography>
          </Grid>
        </Grid>
        <Grid container mb={2}>
          <Grid item xs={12} md={3}>
            <Typography variant="subtitle1" fontWeight="bold">
              Email
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1">{user?.email}</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <ButtonDefault
              variant="outlined"
              onClick={() => changeEmailDialogStoreAction(true)}
              className={classes.changeEmailButton}
            >
              Actualizar
            </ButtonDefault>
          </Grid>
        </Grid>
        {socialProvider && (
          <Grid container mb={2}>
            <Grid item xs={5} md={3}>
              <Typography variant="subtitle1" fontWeight="bold">
                Conectado con
              </Typography>
            </Grid>
            <Grid item xs={7} md={9}>
              <Typography variant="subtitle1">{`Cuenta ${socialProvider}`}</Typography>
            </Grid>
          </Grid>
        )}
      </Box>
      <Grid container>
        <Grid item xs={12} sm={3}>
          <Typography fontWeight={'600'}>Conectado con</Typography>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <GoogleConnectButton />
            </Grid>
            <Grid item xs={12}>
              <FacebookConnectButton />
            </Grid>
            <Grid item xs={12}>
              <AppleConnectButton />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DialogDefault
        isOpen={isOpen}
        handleClose={() => providerConnectStoreAction(false, undefined)}
        title="Desconectar"
        isLoading={loading}
        showActions
        handleSuccess={() =>
          callDeleteAction({
            variables: {
              data: {
                provider,
              },
            },
          })
        }
      >
        <div style={{ fontSize: 18, padding: '20px 0' }}>
          {`¿Estas seguro que deseas desconectar tu cuenta de ${provider?.toLowerCase()}?`}
        </div>
      </DialogDefault>
      <DialogDefault
        isOpen={open}
        handleClose={() => changeEmailDialogStoreAction(false)}
        title="Cambiar correo eletrónico"
        isLoading={loadingUpdateEmail}
        showActions
        handleSuccess={() =>
          callUpdateEmailAction({
            variables: {
              data: {
                new_email: changeEmailData.email,
                password: changeEmailData.password,
              },
            },
          })
        }
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              placeholder={'Nuevo correo electrónico'}
              name="email"
              fullWidth
              value={changeEmailData?.email}
              InputLabelProps={{
                shrink: true,
                style: { fontSize: 19 },
              }}
              variant="outlined"
              InputProps={{
                className: classes.input,
              }}
              onChange={(event) => onChangeHandler(event)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              placeholder="Contraseña"
              name="password"
              type="password"
              fullWidth
              value={changeEmailData.password as string}
              InputLabelProps={{
                shrink: true,
                style: { fontSize: 19 },
              }}
              variant="outlined"
              InputProps={{
                className: classes.input,
              }}
              onChange={(event) => onChangeHandler(event)}
              helperText="Ingrese su contraseña para confirmar que desea cambiar su correo electrónico."
            />
          </Grid>
        </Grid>
      </DialogDefault>
    </>
  );
};
