import { browserName } from 'react-device-detect';

export type EasyDataAuthenticate = {
  uuid: string;
};
export type EasyDataAuthenticateResponse = {
  uuid: string;
  message: string;
};

export enum EasyDataName {
  loginClicked = 'login_clicked',
  registerClicked = 'register_clicked',
  startSessionFacebook = 'start_session_facebook',
  startSession = 'start_session',
  swgStartSession = 'swg_start_session',
  facebookClicked = 'facebook_clicked',
  startSessionGoogle = 'start_session_google',
  startSessionApple = 'start_session_apple',
  authClicked = 'auth_clicked',
  wallCrashesSubscription = 'wall_crashes_subscription',
  wallCrashesRegister = 'wall_crashes_register',
  swgRegisterUser = 'swg_register_user',
  pageVisit = 'page_visit',
  gmailClicked = 'gmail_clicked',
  registerUser = 'register_user',
  wallCrashesModal = 'wall_crashes_modal',
  analyticsAuthenticate = 'analytics_authenticate',
  appleClicked = 'apple_clicked',
  skipSwg = 'skip_swg',
}

export type EasyDataCreateEventHook = {
  createAction: (name: EasyDataName, extra_data?: EventMeta[]) => void;
};

export type EasyDataCreate = {
  id?: number;
  created_at?: string;
  updated_at?: string;
  name?: string;
  event?: { [key: string]: string }[];
};


export type IpInfo = {
  ip?: string;
  hostname?: string;
  city?: string;
  region?: string;
  country?: string;
  loc?: string;
  org?: string;
  timezone?: string;
}

export type GetEventData = {
  deviceType: string;
  browserName: string;
  ip: string;
  osName: string;
  categoryDevice: string;
  country: string;
  url: string;

  section: string;
  author: string;
  post_id: string;
  author_id: string;
}

export type EventMeta = {
  meta_key?: string;
  meta_value?: string;
}

export type EasyDataEventCreate = {
  user_id: number; uuid: string; name: EasyDataName, extra_data?: EventMeta[] }