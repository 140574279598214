export const SETTING_ROUTES = [
  {
    path: '/',
    name: 'Perfil',
  },
  {
    path: '/mis-notas-guardadas',
    name: 'Mis notas guardadas',
  },
  {
    path: '/facturas',
    name: 'Facturas',
  },
  {
    path: '/suscripciones',
    name: 'Suscripciones',
  },
  {
    path: '/notificaciones',
    name: 'Notificaciones',
  },
  {
    path: '/detalles-de-facturacion',
    name: 'Detalles de facturacion',
  },
  {
    path: '/newsletters',
    name: 'Newsletters',
  },
  {
    path: '/historial',
    name: 'Historial',
  },
];
