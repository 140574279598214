import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { PlanSectionTitle } from '../../../shared/components/Text';
import { useBreakPoint } from '../../../shared/hooks/useBreakPoint';

const createData = (
  name: string,
  app: string,
  web: string,
  free: boolean,
): { name: string; app: string; web: string; free: boolean } => {
  return { name, app, web, free };
};

const rows = [
  createData('Acceso a notas de interes general', 'si', 'si', true),
  createData('Personalizar Notificaciones', 'si', 'si', true),
  createData('Modo oscuro, cambiar tamaño de letra.', 'si', 'No', true),
  createData(
    'Acceso ilimitado a articulos y autores de opinion',
    'si',
    'si',
    false,
  ),
  createData(
    'Acceso a Semanario, periodismo de investigacion',
    'si',
    'si',
    false,
  ),
  createData('Comentarios en notas', 'si', 'si', false),
  createData('Acceso a descuentos de Membresia Vanguardia', 'si', 'si', false),
  createData('Reaccionar al articulo', 'si', 'si', false),
  createData('Guardar articulos para lectura posterior', 'si', 'si', false),
  createData(
    'Lectura sin interrupciones ni anuncios',
    'si',
    'Proximamente',
    false,
  ),
  createData('Acceso a Vanguardia HD (E-Paper)', 'si', 'Proximamente', false),
  createData(
    'Escuchar articulos y crear lista de reproduccion',
    'si',
    'Proximamente',
    false,
  ),
  createData('Seccion personalizada con temas de interés', 'si', 'no', false),
  createData('Video galeria', 'proximamente', 'proximamente', false),
  createData('Foto Galeria', 'proximamente', 'proximamente', false),
  createData('Juegos Vanguardia', 'proximamente', 'proximamente', false),
  createData(
    'Inteligencia Artificial Generativa',
    'proximamente',
    'proximamente',
    false,
  ),
  createData('Traduccion instantanea', 'proximamente', 'proximamente', false),
];

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    tableHeaders: {
      borderBottom: 'none',
      whiteSpace: 'nowrap',
      color: '#ffffff !important',
      fontWeight: '700 !important',
      fontSize: '18px !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '16px !important',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '13px !important',
      },
    },
    tableRow: {
      borderBottom: 'none',
      background: '#ffffff',
      fontSize: '16px !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '14px !important',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px !important',
      },
    },
  }), 
);
export const PlansSectionThree: React.FC = () => {
  const { isBelow } = useBreakPoint('sm');
  const classes = useStyles();
  return (
    <Box>
      <PlanSectionTitle marginBottom={2}>
        Compara los beneficios
      </PlanSectionTitle>
      <TableContainer style={{ background: 'none', boxShadow: 'none' }}>
        <Table
          style={{ borderSpacing: '5px', borderCollapse: 'separate' }}
          aria-label="simple table">
          <TableHead style={{ borderBottom: '5px solid black' }}>
            <TableRow style={{ background: '#000000' }}>
              <TableCell className={classes.tableHeaders}>Beneficio</TableCell>

              <TableCell className={classes.tableHeaders} align="center">
                App +
              </TableCell>
              <TableCell className={classes.tableHeaders} align="center">
                Web +
              </TableCell>
              <TableCell className={classes.tableHeaders} align="center">
                Gratis
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name} sx={{ border: 'none' }}>
                <TableCell
                  className={classes.tableRow}
                  component="th"
                  scope="row">
                  {row.name}
                </TableCell>

                <TableCell
                  className={classes.tableRow}
                  align="center"
                  title={isBelow && row.app.length > 3 ? row.app : ''}
                  sx={{
                    color: row.app === 'si' ? 'green' : 'red',
                    textTransform: 'capitalize',
                    ...(row.app.toLowerCase() === 'proximamente' && {
                      color: 'orange',
                    }),
                  }}>
                  {isBelow ? row.app.substring(0, 4).concat('.') : row.app}
                </TableCell>
                <TableCell
                  className={classes.tableRow}
                  align="center"
                  title={isBelow && row.web.length > 3 ? row.web : ''}
                  sx={{
                    color: row.web === 'si' ? 'green' : 'red',
                    textTransform: 'capitalize',
                    ...(row.web.toLowerCase() === 'proximamente' && {
                      color: 'orange',
                    }),
                  }}>
                  {isBelow ? row.web.substring(0, 4).concat('.') : row.web}
                </TableCell>
                <TableCell
                  className={classes.tableRow}
                  align="center"
                  sx={{
                    textTransform: 'capitalize',
                    color: row.free ? 'green' : 'red',
                  }}>
                  {row.free ? 'Si' : 'No'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
