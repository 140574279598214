import { createAction } from '@cobuildlab/react-simple-state';
import {
  createPaymentMethodEvent,
  createPaymentMethodEventError,
  createPaymentSetupEvent,
  createPaymentSetupEventError,
} from './card-events';
import { stripe } from '../../shared/stripe/config';
import { StripeElements, Stripe as StripeFront } from '@stripe/stripe-js';

export const createPaymentSetup = createAction(
  createPaymentSetupEvent,
  createPaymentSetupEventError,
  async () => {
    const setupIntent = await stripe.setupIntents.create({
      usage: 'off_session',
    });
    return setupIntent;
  },
);

export const createPaymentMethod = createAction(
  createPaymentMethodEvent,
  createPaymentMethodEventError,
  async (
    elements: StripeElements | null,
    stripeFront: StripeFront | null,
    clientSecret: string,
  ) => {
    if (!stripeFront || !elements) {
      throw new Error('Instance not found.');
    }

    await elements.submit();

    const payElement = await stripeFront.confirmSetup({
      elements,
      clientSecret: clientSecret,
      redirect: 'if_required',
    });
    const paymentMethodId = payElement.setupIntent?.payment_method as string;

    if (!paymentMethodId) throw new Error('Failed to create payment method.');

    return paymentMethodId;
  },
);
