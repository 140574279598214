import React, { useState } from 'react';
import { useAuth } from '../../auth/auth-hook';
import { Box, Stack, Theme, Typography } from '@mui/material';
import { AccessTime } from '@mui/icons-material';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';
import { createStyles, makeStyles } from '@mui/styles';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useCallAction } from '@cobuildlab/react-simple-state';
import { createPaymentMethod } from '../card-actions';
import { useCreatePaymentSetup } from '../card-hooks';
import { useUpdateCardMutation } from '../../../shared/types/generated';
import { openDialogAlert } from '../../alert/alert-actions';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    icon: {
      color: '#999999',
      fontSize: '20px!important',
      paddingRight: '5px',
    },
    input: {
      background: 'white',
      fontSize: '18px',
      height: '48px',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      border: '1px solid #9CA5AC',
      padding: 10,
    },
    container: {
      borderBottom: '1px solid #9CA5AC',
      borderRight: '1px solid #9CA5AC',
      borderLeft: '1px solid #9CA5AC',
      borderRadius: '0 0 5px 5px',
      padding: 10,
    },
    button: {
      minWidth: '140px!important',
    },
  }),
);

export const StripeCardItem: React.FC = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { user, refetch: refetchUser } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [formReady, setFormReady] = useState(false);
  const { data, refetch: refetchPSetup } = useCreatePaymentSetup();
  const stripeFront = useStripe();
  const elements = useElements();

  const subscription = user?.subscriptions?.length
    ? user?.subscriptions[0]
    : undefined;
  const card = user?.card;

  const [callAction] = useUpdateCardMutation({
    onCompleted: () => {
      refetchUser();
      setIsOpen(false);
      setLoading(false);
    },
    onError: (err) => {
      setLoading(false);
      console.error(err);
      openDialogAlert(
        'Tenemos problemas para registrar el nuevo metodo de pago.',
      );
    },
  });

  const [callCreatePM] = useCallAction(createPaymentMethod, {
    onCompleted: (stripePaymentMethodId) => {
      refetchPSetup();
      callAction({
        variables: {
          data: {
            stripePaymentMethod: stripePaymentMethodId,
          },
        },
      });
    },
    onError: (err) => {
      setLoading(false);
      console.error(err);
      openDialogAlert(
        'Tenemos problemas para registrar el nuevo metodo de pago.',
      );
    },
  });

  const handleSubmit = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    setLoading(true);
    callCreatePM(elements, stripeFront, data?.client_secret ?? '');
  };

  return (
    <>
      <Box
        className={classes.header}
        borderRadius={isOpen ? '5px 5px 0 0' : '5px'}>
        <Box display={'flex'} alignItems={'center'} color={'#707070'}>
          <Box display={'flex'}>
            <img
              alt="icon-Card"
              src={'/card-icon.svg'}
              width={20}
              height={20}
            />
          </Box>
          <Box ml={'15px'}>
            <Typography fontWeight={800}>
              {card?.brand}{' '}
              {card?.card_number?.replaceAll('X', '*').split(' ', 4)}
            </Typography>
            <Box display={'flex'}>
              <AccessTime fontSize="small" />
              <Typography ml={'5px'} fontSize={14}>
                Vence el {card?.expiration_month}/{card?.expiration_year}
              </Typography>
            </Box>
          </Box>
        </Box>
        {subscription?.status === 'ACTIVE' && (
          <Box>
            <ButtonDefault
              variant="contained"
              size="large"
              onClick={() => setIsOpen(!isOpen)}
              className={classes.button}>
              {!isOpen ? 'Actualizar' : 'Cerrar'}
            </ButtonDefault>
          </Box>
        )}
      </Box>
      {isOpen ? (
        <Box className={classes.container}>
          <Stack
            direction="column"
            justifyContent="space-evenly"
            alignItems="stretch"
            spacing={3}
            component="form"
            onSubmit={handleSubmit}
            pt={2}>
            <PaymentElement
              onChange={(event) => setFormReady(event.complete)}
            />
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <ButtonDefault
                type="submit"
                variant="contained"
                size="large"
                fullWidth
                isLoading={loading}
                disabled={!stripeFront || !formReady}
                className={classes.button}>
                Guardar
              </ButtonDefault>
            </Box>
          </Stack>
        </Box>
      ) : null}
    </>
  );
};
