import React, { useEffect, useState } from 'react';
import { CardSection } from '../../shared/components/card/CardSection';
import { Typography } from '@mui/material';
import {
  useNotificationsLazyQuery,
  Notification,
} from '../../shared/types/generated';
import { useAuth } from '../auth/auth-hook';
import moment from 'moment/moment';
import { TableDefault } from '../../shared/components/table/TableDefault';
import { TableColumnType } from '../../shared/components/table/table-types';

export const Notifications: React.FC = () => {
  const { user } = useAuth();
  const [pagination, setPagination] = useState({
    pageSize: 10,
    page: 1,
    cursorStack: [null] as Array<string | null>,
  });
  const [count, setCount] = useState(0);

  const [callAction, { data, loading }] = useNotificationsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      console.log(res);
      setCount(res.notificationList?.pageInfo?.totalCount || 0);
    },
    onError: (error) => {
      
      console.log(JSON.stringify(error, null, 2));
    },
  });

  useEffect(() => {
    if (user) {
      callAction({
        variables: {
          where: {
            user_id: user.id,
          },
          after: pagination.cursorStack[pagination.page - 1],
          first: pagination.pageSize,
        },
      });
    }
  }, [user, callAction, pagination]);

  const handleChangePage = (newPage: number): void => {
    if (newPage > pagination.page) {
      setPagination((prev) => ({
        ...prev,
        page: newPage,
        cursorStack: [
          ...prev.cursorStack,
          data?.notificationList?.pageInfo?.endCursor ?? null,
        ],
      }));
    } else {
      setPagination((prev) => ({
        ...prev,
        page: newPage,
      }));
    }
  };

  const notifications = (data?.notificationList?.edges || []).map(
    (item) => item?.node as Notification,
  );

  const COLUMNS: TableColumnType<Notification>[] = [
    {
      columnName: 'Fecha',
      columnValue: (item: Notification) =>
        moment(parseInt(item.created_at as string)).format('DD-MM-YYYY HH:mm'),
    },
    {
      columnName: 'Mensaje',
      columnValue: (item: Notification) => item.message as string,
    },
  ];

  return (
    <CardSection
      title={
        <Typography variant="h6" fontWeight={800}>
          Notificaciones
        </Typography>
      }
      subheader={
        <Typography variant="subtitle1">
          Acá encontrarás información detallada del historial de notificaciones.
        </Typography>
      }>
      <TableDefault
        count={count}
        items={notifications}
        columns={COLUMNS}
        page={pagination.page}
        pageSize={pagination.pageSize}
        loading={loading}
        messageLoading={'Cargando notificaciones...'}
        showPagination
        handleChangePage={(page) => handleChangePage(page + 1)}
      />
    </CardSection>
  );
};
