import React, { useEffect, useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

type SliderProps = {
  items?: React.ReactNode[];
  infinite?: boolean;
  onlyOne?: boolean;
};

export const SliderFeaturedColumninst: React.FC<SliderProps> = ({
  items,
  infinite,
  onlyOne = false,
}) => {
  const [responsive, setResponsive] = useState({});
  const [renderKey, setKey] = useState(0);

  useEffect(() => {
    setKey(Date.now());
  }, []);
  useEffect(() => {
    setResponsive({
      0: {
        items: 3,
        itemsFit: 'contain',
      },
      520: {
        items: 4,
        itemsFit: 'contain',
      },
      600: {
        items: 5,
        itemsFit: 'contain',
      },
      720: {
        items: 6,
        itemsFit: 'contain',
      },
      940: {
        items: 8,
        itemsFit: 'contain',
      },
      1240: {
        items: 10,
        itemsFit: 'contain',
      },
    });
  }, []);

  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          position: 'relative',
          objectFit: 'contain',
        }}>
        <AliceCarousel
          renderKey={renderKey}
          autoHeight={onlyOne}
          autoWidth={onlyOne}
          mouseTracking
          items={items}
          responsive={responsive}
          infinite={infinite}
          renderDotsItem={() => null}
          renderPrevButton={() => null}
          renderNextButton={() => null}
        />
      </div>
    </div>
  );
};
