import { CircularProgress } from '@mui/material';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const AlreadySubscribed: FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/suscripciones');
  }, [navigate]);

  return <CircularProgress />;
};
