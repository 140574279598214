interface FormatNumberOptionsType {
  locale?: string;
  decimalDigits?: number;
  currency?: string;
}

export const formatNumber = (
  number: number,
  options?: FormatNumberOptionsType,
): string => {
  const locale = options?.locale ?? 'ES-MX';
  const decimalDigits = options?.decimalDigits ?? 2;
  const currency = options?.currency ?? 'MXN';

  const value = number.toLocaleString(locale, {
    minimumFractionDigits: decimalDigits,
    maximumFractionDigits: decimalDigits,
    useGrouping: true,
    ...(currency && {
      style: 'currency',
      currency,
    }),
  });

  return value;
};
