import React from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { Routes } from './routes';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './shared/css/theme';
import { ToastProvider } from 'react-toast-notifications';
import { DialogAlert } from './modules/alert/DialogAlert';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { ApolloProvider } from '@apollo/client';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { CookiesProvider } from 'react-cookie';
import { AuthProvider } from './modules/auth/AuthProvider';
import { GOOGLE_API_KEY } from './shared/constans';
import { client } from './shared/apollo';
import { useEasyDataAuthenticate } from './modules/easy-data/easy-data-hooks';
/**
 * App component.
 *
 * @returns {React.FC} - App component.
 */
export const App: React.FC = () => {

  useEasyDataAuthenticate();

  return (
    <ToastProvider placement="top-right" autoDismiss autoDismissTimeout={10000}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <ApolloProvider client={client}>
            <GoogleOAuthProvider clientId={GOOGLE_API_KEY as string}>
              <CookiesProvider>
                <AuthProvider>
                  <Routes />
                  <DialogAlert />
                </AuthProvider>
              </CookiesProvider>
            </GoogleOAuthProvider>
          </ApolloProvider>
        </ThemeProvider>
      </BrowserRouter>
    </ToastProvider>
  );
};
