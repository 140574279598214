import {
  Box,
  Grid,
  List,
  ListItem,
  Theme,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    imgVoice: {
      height: 336,
      width: 436,
      objectFit: 'cover',
      marginBottom: 0,
      [theme.breakpoints.down('md')]: {
        height: 310,
        width: 340,
        marginBottom: 10,
      },
      [theme.breakpoints.down('sm')]: {
        height: 310,
        width: '100%',
        marginBottom: 20,
      },
    },
    textTitle: {
      lineHeight: '28px !important',
      fontSize: '24px !important',
      [theme.breakpoints.down('md')]: {
        lineHeight: '24px !important',
        fontSize: '18px !important',
      },
    },
    texts: {
      lineHeight: '28px !important',
      fontSize: '24px !important',
      [theme.breakpoints.down('md')]: {
        lineHeight: '24px !important',
        fontSize: '16px !important',
      },
    },
  }),
);
export const PlansSectionOne: React.FC = () => {
  const classes = useStyles();
  return (
    <Box>
      <Grid container padding={'10px 10px'} bgcolor={'#ffffff'}>
        <Grid
          item
          xs={12}
          sm={5}
          md={4}
          borderRadius={1}
          overflow={'hidden'}
          display="flex"
          alignItems={'center'}
          justifyContent="center">
          <img
            src="./protagonista.png"
            alt="Megafono alerta"
            className={classes.imgVoice}
          />
        </Grid>
        <Grid item xs={12} sm={7} md={8}>
          <Box pl={2} pt={2}>
            <Box overflow={'hidden'} display={'inline-flex'} ml={-1}>
              <Typography
                className={classes.textTitle}
                borderRadius={'10px'}
                px={1}
                py={'5px'}
                bgcolor={'#000000'}
                color={'#ffffff'}
                fontWeight={700}>
                Tu membresía, ¡SE PAGA SOLA!
              </Typography>
            </Box>
            <Typography className={classes.texts} my={2}>
              Únete a <strong>Vanguardia Premium</strong> y obtén:
            </Typography>
            <List sx={{ listStyleType: 'disc', pl: 4 }}>
              <ListItem sx={{ display: 'list-item', p: 0 }}>
                <Typography className={classes.texts}>
                  Aprovecha <strong>ofertas y descuentos exclusivos</strong> en
                  más de 60 negocios.
                </Typography>
              </ListItem>
              <ListItem sx={{ display: 'list-item', p: 0 }}>
                <Typography className={classes.texts}>
                  Lee nuestras{' '}
                  <strong>
                    investigaciones, artículos de opinión y autores
                  </strong>{' '}
                  premium.
                </Typography>
              </ListItem>
              <ListItem sx={{ display: 'list-item', p: 0 }}>
                <Typography className={classes.texts}>
                  Navegación web{' '}
                  <strong>sin anuncios ni interrupciones.</strong>
                </Typography>
              </ListItem>
            </List>
            <Typography className={classes.texts} mt={2}>
              Esto y más, <strong>POR SOLO $6 MXN AL DÍA</strong>.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
