import React, { useState, ChangeEvent } from 'react';
import { useAuth } from '../../auth/auth-hook';
import { Box, Grid, TextField, Theme, Typography } from '@mui/material';
import {
  AccessTime,
  CalendarMonth,
  CreditCard,
  Key,
  Person,
} from '@mui/icons-material';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';
import InputMask from 'react-input-mask';
import { checkCreditCard } from '../../open-pay/open-pay-utils';
import { openDialogAlert } from '../../alert/alert-actions';
import { createStyles, makeStyles } from '@mui/styles';
import cardValidator from 'card-validator';
import { useUpdateCardMutation } from '../../../shared/types/generated';

type CreditCardType = {
  cardHolderName: string;
  expiredDate: string;
  cvc: string;
  creditCardNumber: string;
};

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    icon: {
      color: '#999999',
      fontSize: '20px!important',
      paddingRight: '5px',
    },
    input: {
      background: 'white',
      fontSize: '18px',
      height: '48px',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      border: '1px solid #9CA5AC',
      padding: 10,
    },
    container: {
      borderBottom: '1px solid #9CA5AC',
      borderRight: '1px solid #9CA5AC',
      borderLeft: '1px solid #9CA5AC',
      borderRadius: '0 0 5px 5px',
      padding: 10,
    },
    button: {
      minWidth: '140px!important',
    },
  }),
);

export const CardItem: React.FC = () => {
  const classes = useStyles();

  const { user, refetch } = useAuth();
  const [creditCard, setCreditCard] = useState<CreditCardType>({
    cardHolderName: '',
    expiredDate: '',
    cvc: '',
    creditCardNumber: '',
  });
  const [isOpen, setIsOpen] = useState(false);

  const [callAction, { loading }] = useUpdateCardMutation({
    onCompleted: () => {
      setIsOpen(false);
      refetch();
    },
  });

  const onChangeHandler = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void => {
    setCreditCard({
      ...creditCard,
      [event.target.name as keyof CreditCardType]: event.target.value as string,
    });
  };

  const onHandlePayment = async (): Promise<void> => {
    const checked = checkCreditCard(creditCard);

    if (checked) {
      return openDialogAlert(checked);
    }

    const date = cardValidator.expirationDate(creditCard.expiredDate);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-undef
    const deviceDataId = OpenPay.deviceData.setup();
    const sanitizeCreditCard = {
      card_number: (creditCard.creditCardNumber as string).replaceAll(' ', ''),
      holder_name: creditCard.cardHolderName as string,
      cvv2: creditCard.cvc as string,
      expiration_month: date.month as string,
      expiration_year: date.year as string,
      device_session_id: deviceDataId,
    };

    callAction({
      variables: {
        data: {
          ...sanitizeCreditCard,
        },
      },
    });
  };

  const subscription = user?.subscriptions?.length
    ? user?.subscriptions[0]
    : undefined;
  const card = user?.card;

  return (
    <>
      <Box
        className={classes.header}
        borderRadius={isOpen ? '5px 5px 0 0' : '5px'}>
        <Box display={'flex'} alignItems={'center'} color={'#707070'}>
          <Box display={'flex'}>
            <img
              alt="icon-Card"
              src={'/card-icon.svg'}
              width={20}
              height={20}
            />
          </Box>
          <Box ml={'15px'}>
            <Typography fontWeight={800}>
              {card?.brand}{' '}
              {card?.card_number?.replaceAll('X', '*').split(' ', 4)}
            </Typography>
            <Box display={'flex'}>
              <AccessTime fontSize="small" />
              <Typography ml={'5px'} fontSize={14}>
                Vence el {card?.expiration_month}/{card?.expiration_year}
              </Typography>
            </Box>
          </Box>
        </Box>
        {subscription?.status === 'ACTIVE' && (
          <Box>
            <ButtonDefault
              variant="contained"
              size="large"
              onClick={() => setIsOpen(!isOpen)}
              className={classes.button}>
              {!isOpen ? 'Actualizar' : 'Cerrar'}
            </ButtonDefault>
          </Box>
        )}
      </Box>
      {isOpen ? (
        <Box className={classes.container}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                placeholder={'Nombre del titular'}
                name="cardHolderName"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                  style: { fontSize: 19 },
                }}
                variant="outlined"
                InputProps={{
                  startAdornment: <Person className={classes.icon} />,
                  className: classes.input,
                }}
                onChange={(event) => onChangeHandler(event)}
              />
            </Grid>
            <Grid item xs={12}>
              <InputMask
                mask="9999 9999 9999 9999"
                maskChar=" "
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                maskPlaceholder={null}
                onChange={(event) => onChangeHandler(event)}>
                <TextField
                  placeholder={'Número de tarjeta'}
                  name="creditCardNumber"
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    startAdornment: <CreditCard className={classes.icon} />,
                    className: classes.input,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: { fontSize: 19 },
                  }}
                />
              </InputMask>
            </Grid>

            <Grid item xs={6}>
              <InputMask
                mask="99/99"
                maskChar=" "
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                maskPlaceholder={null}
                onChange={(event) => onChangeHandler(event)}>
                <TextField
                  placeholder={'Fecha de vencimiento'}
                  name="expiredDate"
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    startAdornment: <CalendarMonth className={classes.icon} />,
                    className: classes.input,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: { fontSize: 16 },
                  }}
                />
              </InputMask>
            </Grid>
            <Grid item xs={6}>
              <InputMask
                mask="999"
                maskChar=" "
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                maskPlaceholder={null}
                onChange={(event) => onChangeHandler(event)}>
                <TextField
                  placeholder={'CVV/CVC'}
                  name="cvc"
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    startAdornment: <Key className={classes.icon} />,
                    className: classes.input,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: { fontSize: 16 },
                  }}
                />
              </InputMask>
            </Grid>
            <Grid item xs={12}>
              <ButtonDefault
                variant="contained"
                size="large"
                fullWidth
                isLoading={loading}
                onClick={() => onHandlePayment()}
                className={classes.button}>
                Guardar
              </ButtonDefault>
            </Grid>
          </Grid>
        </Box>
      ) : null}
    </>
  );
};
