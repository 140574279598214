import { useCallAction } from '@cobuildlab/react-simple-state';
import Stripe from 'stripe';
import { useEffect, useState } from 'react';
import { createPaymentSetup } from './card-actions';

interface UseCreatePaymentSetupReturn {
  data: Stripe.SetupIntent;
  refetch: () => void;
  loading: boolean;
  error: Error;
}
export const useCreatePaymentSetup = (): UseCreatePaymentSetupReturn => {
  const [loading, setLoading] = useState(true);

  const [callPaymentSetup, , { error, data }] = useCallAction(
    createPaymentSetup,
    {
      onCompleted: async () => {
        setLoading(false);
      },
      onError: async (err) => {
        console.error(err);
        setLoading(false);
      },
    },
  );

  useEffect(() => {
    if (data) return;
    callPaymentSetup();
  }, [callPaymentSetup, data]);

  return {
    data,
    refetch: () => callPaymentSetup(),
    loading,
    error,
  };
};
